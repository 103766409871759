import * as yup from 'yup';
import { REGEXP_DISPLAY_NAME } from '@app/constants/validation';
const validationSchema = (translateFn) => yup.object().shape({
    portfolioName: yup
        .string()
        .trim()
        .min(1, translateFn('profile.messages.publicname_empty'))
        .matches(REGEXP_DISPLAY_NAME, translateFn('profile.messages.publicname_only_latin_and_numbers'))
        .max(30, ''),
    walletId: yup
        .string()
        .required(),
    subscriptionPrice: yup
        .number()
        .nonNullable()
        .min(0, 'monthly following fee should be greater than 0')
        .required(),
});
export default validationSchema;
