import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useState, } from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { useAppSelector } from '@app/store/Hooks';
import ProfilePortfolioCard from '@entities/portfolio/ui/ProfilePortfolioCard';
import { IsDefined } from '@utils/js-ts';
import { ContainerRow } from '@src/components/styled';
import { PortfolioEdit } from '@features/portfolio/portfolio-edit';
import { CreatePortfolioButton } from '@features/portfolio/portfolio-create';
const PortfolioListContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    flexWrap: 'wrap',
    alignItems: 'end',
}));
const PortfolioView = ({ portfolio, portfolioIdToEdit = undefined, selected, onClick, onAction, onEditClose, }) => {
    const handleOnClick = () => {
        onClick(portfolio.id);
    };
    return (_jsxs(_Fragment, { children: [(portfolioIdToEdit !== portfolio.id) &&
                (_jsx(ProfilePortfolioCard, { portfolio: portfolio, viewType: 'card', selected: selected, onClick: handleOnClick, onAction: onAction })), IsDefined(portfolioIdToEdit) &&
                portfolioIdToEdit === portfolio.id && (_jsx(PortfolioEdit, { portfolio: portfolio, onEditClose: onEditClose }))] }));
};
const PortfolioList = ({ portfolios: originalPortfoliosProp, onSelectPortfolioId, selectedPortfolioId = undefined, maxCount = 10, displayAddPortfolioBtn = false, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const [portfolioToEdit, setPortfolioToEdit] = useState(undefined);
    const portfolios = originalPortfoliosProp.map((portfolio) => (Object.assign(Object.assign({}, portfolio), { 
        // TODO: remove, done to enable edit tests
        profile_id: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) || portfolio.profileId, owner_profile_id: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) || portfolio.profileId })));
    const selectPortfolio = (id) => {
        onSelectPortfolioId(id);
    };
    const onPortfolioAction = (event, actionType, portfolioId) => {
        if (actionType === 'edit') {
            setPortfolioToEdit(portfolios.find(p => p.id === portfolioId));
        }
    };
    const onEditClose = () => {
        setPortfolioToEdit(undefined);
    };
    return (_jsxs(PortfolioListContainer, { children: [portfolios.map((portfolio) => (_jsx(PortfolioView, { portfolio: portfolio, portfolioIdToEdit: portfolioToEdit === null || portfolioToEdit === void 0 ? void 0 : portfolioToEdit.id, selected: portfolio.id === selectedPortfolioId, onClick: selectPortfolio, onAction: onPortfolioAction, onEditClose: onEditClose }, portfolio.id))), portfolios.length < maxCount &&
                displayAddPortfolioBtn &&
                !IsDefined(selectedPortfolioId) &&
                (_jsx(CreatePortfolioButton, { style: {
                        width: 422,
                        height: 308,
                        maxHeight: 'unset',
                        border: `2px dashed ${theme.palette.primary.main}`,
                    }, startIcon: PlusIcon, variant: 'tinted', children: t('overview.portfolios_list.create_portfolio') }))] }));
};
export default PortfolioList;
